import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import ActEmptyPage from './ActEmtyPage';
import ActSuggestionPage from './ActSuggestionPage';
import store  from './store';
import ActSuggestionProgressPage from './ActSuggestionProgressPage';
interface ActSuggestionScreenProps {
}

const ActSuggestionScreen: React.FC<ActSuggestionScreenProps> = observer((props) => {
  const handleUpload = () => {
    // Create a file input element
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    // Only accept Excel files
    fileInput.accept = '.xlsx';
    // Explicitly set to false to ensure only single file selection
    fileInput.multiple = false;
    
    // Handle file selection
    fileInput.onchange = (event) => {
      const files = (event.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const selectedFile = files[0];
        store.parseExcelFile(selectedFile);
        // Here you can add code to process the file
        // For example, you might want to call an API to upload the file
      }
    };
    
    // Trigger the file picker dialog
    fileInput.click();
  };

  useEffect(() => {
    store.getActionSuggestions();
    
    // Cleanup function to prevent memory leaks when component unmounts
    return () => {
      store.clearStore();
    };
  }, []);

  return (
    <>
      {
        store.status === 'EXCEL_IMPORT_ONGOING' ? (
          <ActSuggestionProgressPage  isInfinite={store.progress === null} progress={store.progress}/>
        ) : (
          store.actionSuggestions.length === 0 && (!store.searchValue || store.searchValue === undefined) && (!store.filterValue || store.filterValue === undefined) ? (
            <ActEmptyPage onUpload={handleUpload} />
          ) : (
            <ActSuggestionPage onUpload={handleUpload} />
          )
        )
      }
    </>
  );
});

export default ActSuggestionScreen;
