import { TNewWorkspace } from "types/workspace";
import { FC, ReactElement, useEffect, useState } from "react";

import Radio from "@mui/material/Radio";
import Button from "components/button/Button";
import styled from "styled-components";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import CustomModal from "components/modal/Modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LOGIN_OPTIONS, LoginOptions } from "constants/signInOptions";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";
import { UNAVAILABLE_LOGIN_METHODS_FEATURE_FLAG } from "constants/feature_flag";

type ModalCreateWorkspaceSigninTypeProps = {
  open: boolean;
  hide: (state: boolean) => void;
  workspaceData: TNewWorkspace;
  handleChangeWorkspaceData: (name: string, value: string) => void;
  createNewWorkspace: () => void;
};

const ModalCreateWorkspaceSigninType: FC<
  ModalCreateWorkspaceSigninTypeProps
> = (props): ReactElement => {
  const [availableSignInMethods, setAvailableSignInMethods] = useState<LoginOptions>([]);

  useEffect(() => {
    getFeatureFlagStatus(UNAVAILABLE_LOGIN_METHODS_FEATURE_FLAG).then((response) => {
      if (response.status === 200 || response.status === 201) {
        const unavailableLoginMethods = response.data[process.env.REACT_APP_FEATURE_FLAG_ENV ?? "development"];
        const availableMethods = LOGIN_OPTIONS.filter(option => !unavailableLoginMethods.includes(option.id))
        setAvailableSignInMethods(availableMethods);
      } else {
        setAvailableSignInMethods(LOGIN_OPTIONS);
      }
    }).catch((error) => {
      console.error("Error getting feature flag status", error);
      setAvailableSignInMethods(LOGIN_OPTIONS);
    })
  }, [])
  const handleCancel = () => {
    props.hide(false);
    props.handleChangeWorkspaceData("logo", null);
    props.handleChangeWorkspaceData("name", null);
  };

  return (
    <CustomModal open={props.open} hide={props.hide} width="468">
      <StyledModalTitle id="Workspace_createWorkspacePopUp_Title">
        How will workspace users on {props.workspaceData.name} sign in?
      </StyledModalTitle>
      <StyledModalContent aria-label="Create workspace form">
        <FormControl fullWidth>
          <RadioOptions
            aria-labelledby="signin-options-group"
            name="signin-options"
            value={props.workspaceData.sign_in_method}
            onChange={(e) =>
              props.handleChangeWorkspaceData(
                "sign_in_method",
                e.target.value
              )
            }
          >
            {availableSignInMethods.map((option, index) => (
              <OptionWrapper key={option.id}>
                <StyledRadio
                  id={`Workspace_createWorkspacePopUp_RadioBtn_${index}`}
                  value={option.value}
                  control={<Radio />}
                  label={
                    option.content ? (
                      <OptionContent>
                        <OptionLabel>{option.name}</OptionLabel>
                        {option.content}
                      </OptionContent>
                    ) : (
                      option.name
                    )
                  }
                />
              </OptionWrapper>
            ))}
          </RadioOptions>
        </FormControl>

        <ButtonContainer>
          <CreateButton
            id="Workspace_createWorkspacePopUp_CreateWorkspace_Btn"
            variant="outlined"
            onClick={props.createNewWorkspace}
          >
            Create workspace
          </CreateButton>
          <CancelButton
            id="Workspace_createWorkspacePopUp_Cancel_Btn"
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </CancelButton>
        </ButtonContainer>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateWorkspaceSigninType;

const StyledModalTitle = styled.div`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 20px;
`;

const StyledModalContent = styled.div`
  width: 100%;
`;

const RadioOptions = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 8px;
  margin-top: 40px;
  margin-bottom: 40px;
`;

const OptionWrapper = styled.div`
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: var(--colorPaletteBlueBackground1Hover, rgba(0, 120, 212, 0.05));
  }
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  padding: 16px;
  margin: 0 !important;
  
  .MuiFormControlLabel-label {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
  }
`;

const OptionContent = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

const OptionLabel = styled.p`
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 16px;
`;

const CreateButton = styled(Button)`
  height: 36px;
  min-width: 144px;
`;

const CancelButton = styled(Button)`
  height: 36px;
  min-width: 75px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;