import React, { useState, useEffect, useCallback } from 'react';
import { Box, Menu, MenuItem, TextField, Typography } from '@mui/material';
import store from './store';
import debounce from 'lodash/debounce';

interface ActPopUpFilterPageProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  activeField: string | null;
  onFieldChange: (field: string) => void;
  onApplyFilter: (field: string, operator: string, value: string) => void;
}

const ActPopUpFilterPage: React.FC<ActPopUpFilterPageProps> = ({
  anchorEl,
  open,
  onClose,
  activeField,
  onFieldChange,
  onApplyFilter,
}) => {
  const [filterValue, setFilterValue] = useState('');
  const [operator, setOperator] = useState('contains');
  const [selectedField, setSelectedField] = useState<string | null>(activeField);
  
  useEffect(() => {
    if (open && activeField) {
      if (store.filterField === activeField && store.filterValue) {
        setFilterValue(store.filterValue || '');
        setOperator(store.filterOperator || 'contains');
      } else {
        setFilterValue('');
        setOperator('contains');
      }
      
      setSelectedField(activeField);
    }
  }, [open, activeField]);

  const debouncedApplyFilter = useCallback(
    debounce((field: string, op: string, value: string) => {
      if (field && value) {
        onApplyFilter(field, op, value);
      }
    }, 500),
    [onApplyFilter]
  );

  const handleFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newValue = e.target.value;
    setFilterValue(newValue);
    
    if (selectedField && newValue) {
      debouncedApplyFilter(selectedField, operator, newValue);
    }
  };

  const handleOperatorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newOperator = e.target.value;
    setOperator(newOperator);
    
    if (selectedField && filterValue) {
      debouncedApplyFilter(selectedField, newOperator, filterValue);
    }
  };

  const handleColumnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newField = e.target.value;
    setSelectedField(newField);
    onFieldChange(newField);
    
    if (newField && filterValue) {
      debouncedApplyFilter(newField, operator, filterValue);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      disablePortal={false}
      disableAutoFocusItem
      keepMounted
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          style: {
            width: '700px',
            padding: '16px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            marginTop: '8px',
          }
        }
      }}
      sx={{
        '& .MuiPaper-root': {
          width: '700px',
          padding: '16px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          marginTop: '8px',
        }
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 2, mb: 3 }}>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Columns</Typography>
          <TextField
            select
            fullWidth
            size="small"
            value={selectedField || ''}
            onClick={(e) => e.stopPropagation()}
            onChange={handleColumnChange}
          >
            <MenuItem value="dimension">Dimension</MenuItem>
            {/* <MenuItem value="title">Title of action suggestions</MenuItem>
            <MenuItem value="description">Description of action suggestions</MenuItem>
            <MenuItem value="dimensionStrength">Dimension strength</MenuItem>
            <MenuItem value="authorName">Author name</MenuItem> */}
          </TextField>
        </Box>
        
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Operator</Typography>
          <TextField
            select
            fullWidth
            size="small"
            value={operator}
            onClick={(e) => e.stopPropagation()}
            onChange={handleOperatorChange}
          >
            <MenuItem value="contains">contains</MenuItem>
            <MenuItem value="equals">equals</MenuItem>
            {/* <MenuItem value="startsWith">starts with</MenuItem>
            <MenuItem value="endsWith">ends with</MenuItem> */}
          </TextField>
        </Box>

        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle2" sx={{ mb: 1 }}>Value</Typography>
          <TextField
            fullWidth
            size="small"
            placeholder="Filter value"
            value={filterValue}
            onClick={(e) => e.stopPropagation()}
            onChange={handleFilterValueChange}
          />
        </Box>
      </Box>
    </Menu>
  );
};

export default ActPopUpFilterPage;
