import React from 'react';
import { Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface ColumnSortIconProps {
  /**
   * Optional margin left
   * @default 1
   */
  marginLeft?: number;
  
  /**
   * Optional onClick handler
   */
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * A component that displays column sort icons (up and down arrows)
 */
const ColumnSortIcon: React.FC<ColumnSortIconProps> = ({
  marginLeft = 1,
  onClick,
}) => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        ml: marginLeft,
        height: '100%',
        lineHeight: 0,
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <KeyboardArrowUpIcon fontSize="small" sx={{ mb: -0.5 }} />
      <KeyboardArrowDownIcon fontSize="small" sx={{ mt: -0.5 }} />
    </Box>
  );
};

export default ColumnSortIcon; 