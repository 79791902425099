import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Checkbox, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Chip, Pagination, Menu, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ColumnSortIcon from './components/ColumnSortIcon';
import DOMPurify from 'dompurify';
import { observer } from 'mobx-react-lite';
import  store  from './store';
import mainStore from "../store";
import debounce from 'lodash/debounce';
import ActPopUpFilterPage from './ActPopUpFilterPage';

interface ActionSuggestion {
  id: number;
  dimension: string;
  title: string;
  description: string;
  dimensionStrength: 'Strong' | 'Neutral' | 'Weak';
  authorName: string;
}

interface ActSuggestionPageProps {
  onUpload?: () => void;
}

const ActSuggestionPage: React.FC<ActSuggestionPageProps> = observer((props) => {
  const createMarkup = (htmlContent: string) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };



  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const [columnMenuAnchorEl, setColumnMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeField, setActiveField] = useState<string | null>(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(null);
  
  const handleColumnMenuOpen = (event: React.MouseEvent<HTMLElement>, columnName: string) => {
    setColumnMenuAnchorEl(event.currentTarget);
    setActiveField(columnName);
  };
  
  const handleColumnMenuClose = () => {
    setColumnMenuAnchorEl(null);
    setActiveField(null);
  };
  
  const handleSortAsc = () => {
    store.setData('sortField', activeField);
    store.setData('sortType', 'asc');
    store.setData('page', 1);
    setSortDirection('asc');
    handleColumnMenuClose();
  };
  
  const handleSortDesc = () => {
    store.setData('sortField', activeField);
    store.setData('sortType', 'desc');
    store.setData('page', 1);
    setSortDirection('desc');
    handleColumnMenuClose();
  };
  
  const handleFilter = (event: React.MouseEvent<HTMLElement>) => {
    setFilterAnchorEl(columnMenuAnchorEl);
    setColumnMenuAnchorEl(null);
    
    if (store.filterField === activeField && store.filterValue) {
      // The filter popup component will handle this via its useEffect
    }
  };
  
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setActiveField(null);
  };
  
  const handleApplyFilter = (field: string, operator: string, value: string) => {
    store.setData('page', 1);
    store.setData('searchValue', '');
    store.setData('filterField', field);
    store.setData('filterOperator', operator);
    store.setData('filterValue', value);
    store.getActionSuggestions();
  };
  
  const handleFilterFieldChange = (field: string) => {
    setActiveField(field);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    store.setData('page', value);
    setPage(value); // Update the local state as well
  };

  useEffect(() => {
    setPage(store.page);
  }, [store.page]);

  const renderStrengthChip = (strength: 'Strong' | 'Neutral' | 'Weak') => {
    let backgroundColor = '';
    let dotColor = '';
    let textColor = 'rgba(0, 0, 0, 0.87)'; // Default text color
    
    if (strength === 'Strong') {
      backgroundColor = '#D8FAFF';
      dotColor = '#0CD196';
    } else if (strength === 'Neutral') {
      backgroundColor = '#F1F1F1';
      dotColor = '#959BA2';
    } else if (strength === 'Weak') {
      backgroundColor = '#FFD8D8';
      dotColor = '#FA6B6B';
    }
    
    return (
      <Chip 
        label={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            {strength}
            <Box 
              component="span" 
              sx={{ 
                width: '6px', 
                height: '6px', 
                borderRadius: '50%', 
                backgroundColor: dotColor,
                display: 'inline-block'
              }} 
            />
          </Box>
        }
        size="small"
        sx={{ 
          borderRadius: '4px',
          backgroundColor: backgroundColor,
          color: textColor,
          '& .MuiChip-label': {
            padding: '0 8px'
          }
        }} 
      />
    );
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      store.setData('searchValue', value);
      store.getActionSuggestions();
    }, 300),
    []
  );

  useEffect(() => {
    if (mainStore.survey && mainStore.survey.id) {
      store.getActionSuggestions();
    }
  }, [mainStore.survey, mainStore.survey?.id, store.filterValue, store.page, store.pageSize, store.sortField, store.sortType]);

  const handleRemoveSort = () => {
    store.setData('sortField', undefined);
    store.setData('sortType', undefined);
    handleColumnMenuClose();
  };

  const renderFilterIcon = (field: string) => {
    const isFilterActive = store.filterField === field && store.filterValue;
    
    if (isFilterActive) {
      return <FilterListIcon sx={{ fontSize: 14, ml: 0.5, color: 'primary.main' }} />;
    }
    return <FilterListIcon sx={{ fontSize: 14, ml: 0.5 }} />;
  };

  const renderColumnHeader = (field: string, label: string) => {
    return (
      <Box 
        sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'var(--colorNeutralForeground2)' }}
        onClick={(e) => {
          handleColumnMenuOpen(e, field);
        }}
      >
        {label}
        
        <Box display="flex" justifyContent="flex-end" gap={1} alignItems="center" sx={{ marginLeft: 'auto' }}>
          {store.filterField === field &&  <FilterListIcon sx={{ fontSize: 14 }} />}
          {store.sortField === field && (
            store.sortType === 'asc' ? 
              <ArrowUpwardIcon sx={{ fontSize: 14 }} /> : 
              <ArrowDownwardIcon sx={{ fontSize: 14 }} />
          )}
          <ColumnSortIcon />
        </Box>
      </Box>
    );
  };

  const handleClearFilter = () => {
    store.setData('filterField', undefined);
    store.setData('filterOperator', undefined);
    store.setData('filterValue', undefined);
    store.getActionSuggestions();
    handleColumnMenuClose();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          placeholder="Search title or dimension"
          size="small"
          value={store.searchValue}
          onChange={(e) => {
            const value = e.target.value;
            store.setData('searchValue', value); // Update the UI immediately
            store.setData('page', 1);
            store.setData('filterField', null);
            store.setData('filterOperator', null);
            store.setData('filterValue', null);
            debouncedSearch(value); // Debounce the API call
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ width: '350px' }}
        />
        <Box>
          <Button 
            variant="outlined"
            onClick={props.onUpload}
            sx={{ mr: 1, textTransform: 'none' }}
          >
            Update Suggestions
          </Button>
          <Button 
            variant="outlined" 
            startIcon={<FileDownloadIcon />}
            sx={{ textTransform: 'none' }}
          >
            Export action suggestion
          </Button>
        </Box>
      </Box>

      {/* Table */}
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead sx={{ backgroundColor: 'var(--colorNeutralForeground8)' }}>
            <TableRow>
              {/* <TableCell padding="checkbox">
                <Checkbox />
              </TableCell> */}
              <TableCell width="15%">
                {renderColumnHeader('dimension', 'Dimension')}
              </TableCell>
              <TableCell width="20%">
                {renderColumnHeader('title', 'Title of action suggestions')}
              </TableCell>
              <TableCell width="35%">
                {renderColumnHeader('description', 'Description of action suggestions')}
              </TableCell>
              <TableCell width="15%">
                {renderColumnHeader('dimensionStrength', 'Dimension strength')}
              </TableCell>
              <TableCell width="15%">
                {renderColumnHeader('authorName', 'Author name')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {store.actionSuggestions.length > 0 ? (
              store.actionSuggestions.map((row, index) => (
                <TableRow 
                  key={row.id}
                  sx={{ 
                    backgroundColor: index % 2 === 0 ? 'white' : 'var(--colorNeutralForeground8)'
                  }}
                >
                  {/* <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell> */}
                  <TableCell>{row.dimension}</TableCell>
                  <TableCell 
                    sx={{ 
                      '& b, & strong': { fontWeight: 'bold' },
                      '& i, & em': { fontStyle: 'italic' },
                      '& small': { fontSize: '0.8em' },
                      '& span[lang]': { fontFamily: 'inherit' }
                    }} 
                    dangerouslySetInnerHTML={createMarkup(row.title)}
                  />
                  <TableCell 
                    sx={{ 
                      '& ul, & ol': { paddingLeft: '20px', margin: '8px 0' },
                      '& p': { margin: '8px 0' },
                      '& h3, & h4': { margin: '10px 0 5px 0' },
                      '& b, & strong': { fontWeight: 'bold' },
                      '& i, & em': { fontStyle: 'italic' },
                      '& u': { textDecoration: 'underline' }
                    }} 
                    dangerouslySetInnerHTML={createMarkup(row.description)}
                  />
                  <TableCell>{renderStrengthChip(row.dimensionStrength)}</TableCell>
                  <TableCell>{row.authorName}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center', py: 14 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'  , gap: 1}}>
                    <SearchIcon sx={{ fontSize: 24 , color: 'var(--colorNeutralForeground6)'}} />
                    <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                      No action suggestion matches your search. Try using a different search term.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        anchorEl={columnMenuAnchorEl}
        open={Boolean(columnMenuAnchorEl)}
        onClose={handleColumnMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ 
          '& .MuiPaper-root': { 
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            marginLeft: '-24px' 
          } 
        }}
      >
        {/* Show "Remove Sort ASC" if this column is already sorted ASC */}
        {store.sortField === activeField && store.sortType === 'asc' ? (
          <MenuItem onClick={handleRemoveSort} sx={{ py: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ArrowUpwardIcon sx={{ fontSize: 14 }} />
              <Typography sx={{ fontSize: 14 }}>Remove Sort ASC</Typography>
            </Box>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleSortAsc} sx={{ py: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ArrowUpwardIcon sx={{ fontSize: 14 }} />
              <Typography sx={{ fontSize: 14 }}>Sort by ASC</Typography>
            </Box>
          </MenuItem>
        )}
        
        {/* Show "Remove Sort DESC" if this column is already sorted DESC */}
        {store.sortField === activeField && store.sortType === 'desc' ? (
          <MenuItem onClick={handleRemoveSort} sx={{ py: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ArrowDownwardIcon sx={{ fontSize: 14 }} />
              <Typography sx={{ fontSize: 14 }}>Remove Sort DESC</Typography>
            </Box>
          </MenuItem>
        ) : (
          <MenuItem onClick={handleSortDesc} sx={{ py: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ArrowDownwardIcon sx={{ fontSize: 14 }} />
              <Typography sx={{ fontSize: 14 }}>Sort by DESC</Typography>
            </Box>
          </MenuItem>
        )}
        
        {/* Show Filter option only for dimension column */}
        {activeField === 'dimension' && (
          store.filterField === activeField && store.filterValue ? (
            <MenuItem onClick={handleClearFilter} sx={{ py: 1.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FilterListIcon sx={{ fontSize: 14 }} />
                <Typography sx={{ fontSize: 14 }}>Clear Filter</Typography>
              </Box>
            </MenuItem>
          ) : (
            <MenuItem onClick={handleFilter} sx={{ py: 1.5 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FilterListIcon sx={{ fontSize: 14 }} />
                <Typography sx={{ fontSize: 14 }}>Filter</Typography>
              </Box>
            </MenuItem>
          )
        )}
      </Menu>

      <ActPopUpFilterPage
        anchorEl={filterAnchorEl}
        open={Boolean(filterAnchorEl)}
        onClose={handleFilterClose}
        activeField={activeField}
        onFieldChange={handleFilterFieldChange}
        onApplyFilter={handleApplyFilter}
      />

      {store.actionSuggestions.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
              VIEWING {Math.min(((store.page - 1) * store.pageSize) + 1, store.totalItems || 0)} - {Math.min((store.page * store.pageSize), store.totalItems || 0)} OF {store.totalItems || 0}
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
              ROWS PER PAGE:
            </Typography>
            <TextField
              select
              size="small"
              value={store.pageSize}
              onChange={(e) => {
                store.setData('page', 1);
                store.setData('pageSize', Number(e.target.value));
              }}
              SelectProps={{
                native: true,
                IconComponent: (props) => (
                  <KeyboardArrowDownIcon {...props} sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                ),
              }}
              sx={{ 
                width: '75px', 
                mr: 2,
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--colorNeutralBackground4)'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'var(--colorNeutralBackground4)'
                }
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </TextField>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '4px',
              overflow: 'hidden'
            }}>
              <IconButton 
                size="small" 
                disabled={store.page === 1}
                onClick={(e) => handleChangePage(e, store.page - 1)}
                sx={{ 
                  color: store.page === 1 ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  borderRadius: '0',
                  width: '30px',
                  height: '30px',
                  padding: '0px',
                }}
              >
                <KeyboardArrowLeftIcon fontSize="small" />
              </IconButton>
              
              {(() => {
                // Create array for visible page numbers
                const pageNumbers = [];
                const maxVisiblePages = 5; // Max number of page buttons to show
                
                if (store.totalPages <= maxVisiblePages + 2) {
                  // Show all pages if total pages are few
                  for (let i = 1; i <= store.totalPages; i++) {
                    pageNumbers.push(i);
                  }
                } else {
                  // Always show first page
                  pageNumbers.push(1);
                  
                  // Calculate start and end of visible page range
                  let startPage = Math.max(2, store.page - Math.floor(maxVisiblePages / 2));
                  let endPage = Math.min(store.totalPages - 1, startPage + maxVisiblePages - 1);
                  
                  // Adjust if we're near the end
                  if (endPage >= store.totalPages - 1) {
                    startPage = Math.max(2, store.totalPages - maxVisiblePages);
                    endPage = store.totalPages - 1;
                  }
                  
                  // Add ellipsis after first page if needed
                  if (startPage > 2) {
                    pageNumbers.push('ellipsis1');
                  }
                  
                  // Add visible page numbers
                  for (let i = startPage; i <= endPage; i++) {
                    pageNumbers.push(i);
                  }
                  
                  // Add ellipsis before last page if needed
                  if (endPage < store.totalPages - 1) {
                    pageNumbers.push('ellipsis2');
                  }
                  
                  // Always show last page
                  pageNumbers.push(store.totalPages);
                }
                
                // Render page numbers
                return pageNumbers.map((pageNum, idx) => {
                  if (pageNum === 'ellipsis1' || pageNum === 'ellipsis2') {
                    return (
                      <Box key={`ellipsis-${idx}`} component="span" sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '30px',
                        height: '30px',
                      }}>...</Box>
                    );
                  }
                  
                  return (
                    <Button
                      key={`page-${pageNum}`}
                      variant="text"
                      size="small"
                      onClick={(e) => handleChangePage(e, pageNum as number)}
                      sx={{
                        minWidth: '30px',
                        height: '30px',
                        padding: '0px',
                        margin: '0',
                        color: store.page === pageNum ? 'primary.main' : 'rgba(0, 0, 0, 0.87)',
                        fontWeight: store.page === pageNum ? 'bold' : 'normal',
                        backgroundColor: store.page === pageNum ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                        borderRadius: '0',
                        borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        }
                      }}
                    >
                      {pageNum}
                    </Button>
                  );
                });
              })()}
              
              <IconButton 
                size="small" 
                disabled={store.page === store.totalPages}
                onClick={(e) => handleChangePage(e, store.page + 1)}
                sx={{ 
                  color: store.page === store.totalPages ? 'rgba(0, 0, 0, 0.26)' : 'rgba(0, 0, 0, 0.54)',
                  '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                  borderRadius: '0',
                  width: '30px',
                  height: '30px',
                  padding: '0px',
                }}
              >
                <KeyboardArrowRightIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});

export default ActSuggestionPage;
