import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import erNote from "assets/images/er-note-img.svg";

interface ActEmptyPageProps {
  onUpload?: () => void;
}

const ActEmptyPage: React.FC<ActEmptyPageProps> = ({ onUpload }) => {
  return (
    <StyledContainer>
      <IconContainer>
        <img src={erNote} alt="er-note" style={{ width: '80px', height: '80px' }} />
      </IconContainer>
      <Typography 
        variant="body1" 
        color="textSecondary"
        sx={{ 
          fontFamily: 'Roboto', 
          fontWeight: 400, 
          fontSize: '16px' 
        }}
      >
        There are no action suggestions yet.
      </Typography>
      <UploadButton
        variant="contained"
        color="primary"
        onClick={onUpload}
      >
        Upload action suggestions
      </UploadButton>
    </StyledContainer>
  );
};

export default ActEmptyPage;


const StyledContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    height: '100%',
    minHeight: '300px',
  }));
  
  const IconContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
  }));
  
  const UploadButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
    textTransform: 'none',
    ...theme.typography.body2,
    borderRadius: '4px',
  }));