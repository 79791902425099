import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";
import { API_URL_GO } from "constants/config";

const getRawSurveyResponses = (surveyId: number) => {
  var url = API_URL_GO + `/sm/sm_question/GetRawSurveyResponses/${surveyId}`;
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${createAuthService({
        platform: Platform.MAIN_INTERNAL,
        environment: process.env.REACT_APP_ENVIRONMENT as Environment,
      }).getStoredTokens().accessToken}`,
    }
  }).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  });
};

export default getRawSurveyResponses;
