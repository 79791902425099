import { observer } from "mobx-react";
import { toJS } from "mobx";
import {
  DataGrid,
  getGridStringOperators,
  GridFilterInputValueProps,
  GridFilterItem,
  GridSortModel,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import store from "./store";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/button/Button";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { GridColDef } from "@mui/x-data-grid/models";
import dayjs from "dayjs";
import RemoveIcon from "./icons/RemoveIcon";

type SurveysTableProps = {};

const SurveysTable: React.FC<SurveysTableProps> = observer(() => {
  const tableData: GridColDef[] = [
    {
      field: "workspace_name",
      headerName: "Workspace Name",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_WorkspaceName"}>Workspace</span>;
      },
      sortable: true,
      width: 200,
      renderCell: (params) => {
        return (
          <span id={`Survey_List_Table_WorkspaceName_${params.row.id}`}>
            {params.row.workspace_name}
          </span>
        );
      },
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains" || operator.value === "equals",
      ),
    },
    {
      field: "entity_name",
      headerName: "Entity Name",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_EntityName"}>Entity</span>;
      },
      sortable: true,
      width: 200,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains" || operator.value === "equals",
      ),
      renderCell: (params) => {
        return (
          <span id={`Survey_List_Table_EntityName_${params.row.id}`}>{params.row.entity_name}</span>
        );
      },
    },
    {
      field: "project_name",
      headerName: "Project Name",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_ProjectName"}>Project name</span>;
      },
      sortable: true,
      width: 200,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains" || operator.value === "equals",
      ),
      renderCell: (params) => {
        return (
          <span id={`Survey_List_Table_ProjectName_${params.row.id}`}>
            {params.row.project_name}
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: "Survey Name",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_SurveyName"}>Survey name</span>;
      },
      sortable: true,
      width: 250,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains" || operator.value === "equals",
      ),
      renderCell: (params) => {
        return <span id={`Survey_List_Table_SurveyName_${params.row.id}`}>{params.row.name}</span>;
      },
    },
    {
      field: "survey_status",
      headerName: "Survey status",
      renderHeader: () => {
        return (
          <span id={"Survey_List_Table_Header_SurveyStatus"}>
            Survey status
          </span>
        );
      },
      sortable: true,
      width: 200,
      filterOperators: [
        {
          label: "is",
          value: "is",
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
              return null;
            }
          },
          InputComponent: StatusesInputValue,
          InputComponentProps: { type: "string" },
        },
      ],
      renderCell: (params) => {
        return (
          <span
            id={`Survey_List_Table_SurveyStatus_${params.row.id}`}
            style={{ textTransform: "capitalize" }}
          >
            {params.row.survey_status}
          </span>
        );
      },
    },
    {
      field: "survey_type",
      headerName: "Survey Type",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_SurveyType"}>Survey type</span>;
      },
      sortable: true,
      width: 200,
      filterOperators: [
        {
          label: "is",
          value: "is",
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!filterItem.field || !filterItem.value || !filterItem.operator) {
              return null;
            }
          },
          InputComponent: TypesInputValue,
          InputComponentProps: { type: "string" },
        },
      ],
      renderCell: (params) => {
        return (
          <span id={`Survey_List_Table_SurveyType_${params.row.id}`}>{params.row.survey_type}</span>
        );
      },
    },
    {
      field: "updated_at",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_UpdatedAt"}>Last update</span>;
      },
      sortable: true,
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        let formattedUpdateDate = "";
        if (params.row.updated_at) {
          const da = dayjs(params.row.updated_at);
          const survey_date_update = dayjs(da).toDate();
          formattedUpdateDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(survey_date_update);
        }
        return (
          <span id={`Survey_List_Table_UpdatedAt_${params.row.id}`}>{formattedUpdateDate}</span>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created at",
      renderHeader: () => {
        return <span id={"Survey_List_Table_Header_CreatedAt"}>Created at</span>;
      },
      sortable: true,
      width: 200,
      disableColumnMenu: true,
      filterable: false,
      renderCell: (params) => {
        let formattedUpdateDate = "";
        if (params.row.created_at) {
          const da = dayjs(params.row.created_at);
          const survey_date_update = dayjs(da).toDate();
          formattedUpdateDate = new Intl.DateTimeFormat("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(survey_date_update);
        }
        return (
          <span id={`Survey_List_Table_CreatedAt_${params.row.id}`}>{formattedUpdateDate}</span>
        );
      },
    },
    {
      field: "id",
      sortable: false,
      width: 130,
      disableColumnMenu: true,
      filterable: false,
      renderHeader: () => <></>,
      renderCell: (params) => {
        return (
          <>
            <div>
              <a
                id={`Survey_List_Table_ViewSurvey_Link_${params.row.id}`}
                href={`/survey-projects/${params.row.id}`}
              >
                <Button
                  id={`Survey_List_Table_ViewSurvey_Button_${params.row.id}`}
                  variant="outlined"
                >
                  View survey
                </Button>
              </a>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <Container>
      <SettingsContainer>
        <StyledSearchInput
          id="Survey_List_Table_SearchInput"
          size="small"
          placeholder="Search recipient name/email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton id="Survey_List_Table_SearchInput_Button">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={store.surveysSearchValue}
          onChange={(e) => store.setSearchValue(e.target.value)}
        />
      </SettingsContainer>
      {store.surveysSearchValue !== "" ? (
        <SearchContainer id="Survey_List_Table_SearchResult">
          <SearchText id="Survey_List_Table_SearchResult_Text">
            Recipient with entered name can be found in {store.surveysTotalCount} survey(s).
          </SearchText>
          <RemoveSearchButton
            id="Survey_List_Table_SearchResult_Remove_Button"
            onClick={() => store.setSearchValue("")}
          >
            <RemoveIcon />
            Remove search term
          </RemoveSearchButton>
        </SearchContainer>
      ) : (
        ""
      )}
      <StyledDataGrid
        rows={store.surveyList}
        columns={tableData}
        paginationModel={{
          page: store.surveysPage,
          pageSize: store.surveysPageSize,
        }}
        rowCount={store.surveysTotalCount}
        pageSizeOptions={[10, 25, 50, 100]}
        rowSelection={false}
        onFilterModelChange={(model) => store.changeFilterSurveys(model)}
        slots={{
          toolbar: GridToolbarFilterButton,
        }}
        paginationMode="server"
        filterMode="server"
        onSortModelChange={(sortModel: GridSortModel) => store.changeSortSurveys(sortModel)}
        onPaginationModelChange={(model) =>
          store.changePaginationSurveys(model.page, model.pageSize)
        }
        sortingMode="server"
      />
    </Container>
  );
});

const StatusesInputValue = observer((props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;
  const { projectStatuses } = toJS(store);
  return (
    <FormControl variant="standard">
      <InputLabel>Value</InputLabel>
      <Select
        value={item.value}
        fullWidth
        onChange={(e) => applyValue({ ...item, value: e.target.value })}
      >
        {projectStatuses
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((x) => {
            return (
              <MenuItem key={x.id} value={x?.id?.toString()}>
                {x.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
});

const TypesInputValue = observer((props: GridFilterInputValueProps) => {
  const { item, applyValue } = props;
  const { surveyTypes } = toJS(store);
  return (
    <FormControl variant="standard">
      <InputLabel>Value</InputLabel>
      <Select
        value={item.value}
        fullWidth
        onChange={(e) => applyValue({ ...item, value: e.target.value })}
      >
        {surveyTypes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((x) => {
            return (
              <MenuItem key={x.id} value={x?.id?.toString()}>
                {x.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
});

export default SurveysTable;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SettingsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0.7;
  background: var(--colorPaletteBlueBackground1);
  padding: 10px;
  margin-bottom: 15px;
`;

const SearchText = styled.div`
  color: var(--colorNeutralForeground1) !important;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
const RemoveSearchButton = styled.div`
  color: var(--colorBrandForeground1);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const StyledSearchInput = styled(TextField)`
  width: 100% !important;
  max-width: 260px !important;
  background-color: var(--colorNeutralBackground1) !important;
  margin: 0 !important;

  div {
    border-radius: 4px !important;
  }

  input {
    padding: 8px 11px !important;
    border-radius: 2px !important;
    font-family: Roboto, sans-serif;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
    color: var(--colorNeutralForeground6);
  }
`;

const StyledDataGrid = styled(DataGrid)`
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 0 !important;

  .MuiDataGrid-columnHeaderTitleContainer {
    background-color: var(--colorPaletteBlueBackground2);
  }

  .MuiDataGrid-virtualScroller {
    min-height: 100px;
  }

  .MuiDataGrid-columnHeaders {
    background-color: var(--colorPaletteBlueBackground2);
    border-radius: 0 !important;
    text-transform: uppercase !important;
  }

  .MuiDataGrid-columnHeader:focus-within,
  .MuiDataGrid-cell:focus,
  .MuiDataGrid-cell:focus-within {
    outline: none !important;
  }

  .MuiDataGrid-cell {
    cursor: pointer !important;
  }

  .Mui-selected {
    background-color: transparent !important;
  }

  .MuiCheckbox-root {
    color: var(--colorBrandForeground1) !important;
  }

  .MuiDataGrid-root {
    border: none !important;
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .Mui-odd {
    background-color: var(--colorNeutralForeground8);
  }

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    border-right: 1px solid var(--graySelectBoxBorder);
  }
`;
