import http from "api/config/https";

export type GetEntitiesByWorkSpaceSuccessResponseData = {
  id: number;
  name: string;
  disabled: boolean;
  created_at: string;
  employeesCount: number;
  entityAdminCount: number;
  signinby: string;
  bold_bi_site_name: string;
  updated_at: string;
  logo_id?: number;
  logo_url?: string;
  details: string;
  workspace_id?: number;
  sign_in_methods?: string[];
  settings?: {
    active_seat_count?: number;
    active_seat_count_total?: number;
    features_count?: number;
    features_count_total?: number;
    employee_count_total?: number;
    admin_count_total?: number;
  };
}[];

export const getEntitiesByWorkspace = async (id: number): Promise<any> => {
  try {
    if(http.isAPIGO){
      return http.getGO(`/entity/GetByworkspaceIDInternal?workspace_id=${id}`);
    } else {
      return http.get(`/entity/GetByworkspace_id?workspace_id=${id}`);
    }  
  } catch (error) {
    throw error;
  }
};
