import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import { AccordionDetails, Divider, AccordionSummary, Accordion } from "@mui/material";
import store from "./store";
import styled from "styled-components";
import DownloadIcon from "./icons/DownloadIcon";
import { useParams } from "react-router-dom";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import SurveyTable from "./SurveyTable";
import { Threshold } from "./Threshold";
import { ReopenSurvey } from "./ReopenSurvey";
import { Recipients } from "./Recipients";
import { AllQuestions } from "./AllQuestions";
import { Benchmarks } from "./Benchmarks";
import RecepientStore from './Recipients/store'
import QuestionsStore from './AllQuestions/store'
import BenchmarksStore from './Benchmarks/store'
import { SURVEY_STATUS_CODES } from "constants/survey-status";
import { useNavigate } from "react-router";
import UpdateAttributes from "./UpdateAttributes";
import { ActSuggestionScreen } from "./act";
import ActSuggestionStore from "./act/store";

type SurveyProjectViewProps = {};

const SurveyProjectView: FC<SurveyProjectViewProps> = observer(() => {

  const params = useParams();
  const navigate = useNavigate();
  const surveyId = params.id;

  useEffect(() => {
    const id: number = parseInt(surveyId);
    if (!isNaN(id)) {
      store.doLoad(id)
      RecepientStore.doLoad(id);
      QuestionsStore.doLoad(id);
      BenchmarksStore.doLoad(id);
      ActSuggestionStore.doLoad(id);
    } else {
      navigate("/something-went-wrong")
    }
    
    // Cleanup function to prevent memory leaks when component unmounts or survey changes
    return () => {
      store.clearStore();
      RecepientStore.clearStore();
      QuestionsStore.clearStore();
      BenchmarksStore.clearStore();
      ActSuggestionStore.clearStore();
    };
  }, [params.id])


  return (
    <Container id="Survey_View_Page">

      <BactToSurveys id="Survey_View_Back_Button" href="/survey-projects">
        {"<"} Back to survey projects management
      </BactToSurveys>

      <TopContainer>
        <LeftSide>
          <Header id="Survey_View_SurveyName">
            {store.survey?.name}
          </Header>
          <SurveyId id="Survey_View_SurveyId">
            Survey ID: {store.survey?.id}
          </SurveyId>
        </LeftSide>

        <RightSide>
          <SurveyExport id="Survey_View_SurveyExport">
            <DownloadIcon /> Raw responses (xlsx): <Export id="Survey_View_SurveyExport_Button" onClick={() => store.downloadRawSurveyResponses()}>Export</Export>
          </SurveyExport>
        </RightSide>

      </TopContainer>

      <Divider />

      <SurveyTable />


      <AccordionWrapped>
        <AccordionSummaryWrapped
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          aria-controls="panel4bh-content"
          id="Survey_View_Confidentiality"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_View_Confidentiality_Text">Confidentiality threshold: {store.survey?.min_responses}</AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <Threshold />
        </AccordionDetails>
      </AccordionWrapped>


      {store.survey?.survey_status_code === SURVEY_STATUS_CODES.complete && <AccordionWrapped>
        <AccordionSummaryWrapped
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          aria-controls="panel4bh-content"
          id="Survey_View_ReopenSurvey"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_View_ReopenSurvey_Text">Reopen Survey</AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <ReopenSurvey />
        </AccordionDetails>
      </AccordionWrapped>}


      <AccordionWrapped>
        <AccordionSummaryWrapped
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          aria-controls="panel4bh-content"
          id="Survey_View_Recipients"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_View_Recipients_Text">Recipients: {store.survey?.participation_count}</AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <Recipients />
        </AccordionDetails>
      </AccordionWrapped>


      <AccordionWrapped>
        <AccordionSummaryWrapped
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          aria-controls="panel4bh-content"
          id="Survey_View_AllQuestions"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_View_AllQuestions_Text">All questions: {QuestionsStore.questionList.length}</AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <AllQuestions />
        </AccordionDetails>
      </AccordionWrapped>


      <AccordionWrapped>
        <AccordionSummaryWrapped
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          aria-controls="panel4bh-content"
          id="Survey_View_Benchmarks"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_View_Benchmarks_Text">Benchmarks</AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <Benchmarks />
        </AccordionDetails>
      </AccordionWrapped>

      <AccordionWrapped>
        <AccordionSummaryWrapped
          expandIcon={<ExpandCircleDownOutlinedIcon />}
          aria-controls="panel4bh-content"
          id="Survey_View_Act"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_View_Act_Text">Action Suggestions: {ActSuggestionStore.unfilteredTotalItems}</AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <ActSuggestionScreen/>
        </AccordionDetails>
      </AccordionWrapped>

      <TableWrapper>
      </TableWrapper>

      <UpdateAttributes />

    </Container>
  );
});

export default SurveyProjectView;

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 80px 150px 48px 150px;
`;


const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`

const BactToSurveys = styled.a`
  color: var(--colorNeutralForeground2, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const Header = styled.div`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 112.5% */
  
`
const SurveyId = styled.div`
  color: var(--colorNeutralForeground2, #707882);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: 0.15px;
  margin-left: 15px;
  margin-top: 10px;
`

const LeftSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const RightSide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Export = styled.div`
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  color: var(--colorBrandForeground1, #1477F8);
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`

const SurveyExport = styled.div`
  display: flex;
  padding: 10px 20px;
  border-radius: var(--borderRadius, 4px);
  background: var(--grenBackgroundColor2, #F5FEFF);
  align-items: center;
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`

const TableWrapper = styled.div`
  margin-top: 20px;
`

const AccordionText = styled.span`
  color: var(--colorNeutralForeground1, #3E4450);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

const AccordionWrapped = styled(Accordion)`
  margin: 20px 0 20px 0;
  border-radius: 10px !important;
  position: unset !important;
`;

const AccordionSummaryWrapped = styled(AccordionSummary)`
`;

const AccordionSummaryDiv = styled.div`
  margin: 20px;
`;

