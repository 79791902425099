import { Outlet, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import styled from "styled-components";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";

import MainStore from "MainStore";
import { useAuth } from "@engagerocketco/auth-sdk";
import { useEffect } from "react";

export const PrivateRoute = observer(() => {
  const location = useLocation();
  const { isAuthenticated, login, loading } = useAuth();

  useEffect(() => {
    if (!loading && !isAuthenticated()) {
      login();
      return;
    }
  }, [loading, isAuthenticated, location.pathname, login]);


  if (loading) {  
    return (
      <Backdrop
        id="Preloader"
        sx={{ color: "var(--colorNeutralBackground1)", zIndex: 1000000 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Snackbar
        id="SnackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={MainStore.openSnackbar}
        onClose={() => MainStore.changeSnackbar(false)}
        autoHideDuration={3000}
        message={MainStore.snackbarMessage}
        key={"bottomleft"}
      >
        <StyledAlert
          id="SnackBar_ Alert"
          elevation={6}
          onClose={() => MainStore.changeSnackbar(false)}
          variant="filled"
          severity={MainStore.snackbarSeverity}
          icon={
            MainStore.snackbarSeverity === "error" ? (
              <StyledCancelIcon />
            ) : (
              <StyledCheckIcon />
            )
          }
        >
          {MainStore.snackbarMessage}
        </StyledAlert>
      </Snackbar>
      <Backdrop
        id="Preloader_2"
        sx={{ color: "var(--colorNeutralBackground1)", zIndex: 1000000 }}
        open={MainStore.loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Outlet />
    </>
  );
});

export default PrivateRoute;


const StyledCancelIcon = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2) !important;
`;

const StyledCheckIcon = styled(CheckCircleIcon)`
  color: var(--colorPaletteGreenBackground2) !important;
`;

const StyledAlert = styled(Alert)`
  background-color: var(--colorNeutralForeground1) !important;
  color: var(--colorNeutralBackground1) !important;
  border-radius: 8px !important;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
