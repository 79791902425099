import { makeAutoObservable } from "mobx";
import { useImpersonateLogin, useImpersonateLogout } from "api/useImpersonateUser"
import { MAIN_FRONT_URL } from "constants/config";

import MainStore from "MainStore";
import { createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";

const authService = createAuthService({
  platform: Platform.MAIN_INTERNAL,
  environment: process.env.REACT_APP_ENVIRONMENT as Environment,
});

class Store {
  templatesFilter: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  sendMessageToReceiverWindow = () => {
    return new Promise((resolve, reject) => {
      const receiverWindow = window.open(MAIN_FRONT_URL + "/impersonate/initiate", "_blank");

      if (!receiverWindow) {
        reject(new Error("Failed to open the receiver window."));
        return;
      }

      function sendMessage() {
        try {
          receiverWindow.postMessage({
            type: "ERImpersonation",
            accessToken: authService.getStoredTokens().accessToken,
            refreshToken: authService.getStoredTokens().refreshToken,
          }, MAIN_FRONT_URL);

          clearInterval(interval);  // Clear the initial interval once the message is successfully sent

          // Start sending the message 5 more times with a 1-second interval
          let messageCount = 0;
          const messageInterval = setInterval(() => {
            if (messageCount >= 5) {
              clearInterval(messageInterval);
              resolve("All messages sent");
            } else {
              try {
                receiverWindow.postMessage({
                  type: "ERImpersonation",
                  accessToken: authService.getStoredTokens().accessToken,
                  refreshToken: authService.getStoredTokens().refreshToken,
                }, MAIN_FRONT_URL);
                messageCount++;
              } catch (error) {
                clearInterval(messageInterval);
                reject(new Error("Failed to send message during retries."));
              }
            }
          }, 1000);

        } catch (error) {
          if (receiverWindow.closed) {
            clearInterval(interval);
            reject(new Error("Receiver window was closed before the message could be sent."));
          }
        }
      }

      const interval = setInterval(() => {
        if (receiverWindow.closed) {
          clearInterval(interval);
          reject(new Error("Receiver window was closed before the message could be sent."));
          return;
        }

        sendMessage();
      }, 2000);
    });
  };


  impersonateLogin = async (accountID) => {
    try {
      MainStore.changeLoader(true);
      const response = await useImpersonateLogin(accountID);
      if (response.status === 200 || response.status === 201) {
        await this.sendMessageToReceiverWindow()
        MainStore.setSnackbar("Successfully impersonated", "success");
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
      return false;
    } finally {
      MainStore.changeLoader(false);
    }
  }

  impersonateLogout = async (accountID) => {
    try {
      MainStore.changeLoader(true);
      const response = await useImpersonateLogout(accountID);
      if (response.status === 200 || response.status === 201) {
        MainStore.setSnackbar("Successfully logged out", "success");
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
      return false;
    } finally {
      MainStore.changeLoader(false);
    }
  }

}

const store = new Store();
export default store;
