export type LoginOption = {
  id: string;
  name: string;
  value: string;
  testId: string;
  content: React.ReactNode | null;
};

export type LoginOptions = LoginOption[];

export type SignInMethodMappings = {
  [key: string]: string;
};

export const LOGIN_OPTIONS: LoginOptions = [
  {
    id: "EMAIL",
    name: "Via email and password",
    value: "EMAIL",
    testId: "Entity_CreateEntityPopUp_RadioBtn_1",
    content: null
  },
  {
    id: "MICROSOFT",
    name: "Login with",
    value: "MICROSOFT",
    testId: "Entity_CreateEntityPopUp_RadioBtn_2",
    content: (
      <img style={{ display: "block", marginTop: "-2px", marginLeft: "-5px" }}
        src="/assets/microsoft_login.png"
        height={24}
        alt="Login with Microsoft"
      />
    )
  },
  {
    id: "SAML_SSO",
    name: "Login with SAML SSO",
    value: "SAML_SSO",
    testId: "Entity_CreateEntityPopUp_RadioBtn_3",
    content: null
  }
];

export const SIGN_IN_METHOD_MAPPINGS: SignInMethodMappings = {
  "SAML_SSO": "Login with SAML SSO",
  "MICROSOFT": "Login with Microsoft",
  "EMAIL": "Sign in via email and password"
}