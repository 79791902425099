import { API_URL, API_URL_GO } from "constants/config";
import MainStore from "MainStore";
import { createAuthClient, createAuthService, Environment, Platform } from "@engagerocketco/auth-sdk";
import { AxiosError } from "axios";


const isAPIGO = true

const authService = createAuthService({
  platform: Platform.MAIN_INTERNAL,
  environment: process.env.REACT_APP_ENVIRONMENT as Environment,
});

const onStatusHandler = {
  403: () => { MainStore.setOpenError403(true); },
  422: (error: AxiosError) => {
    // @ts-ignore
    MainStore.setSnackbar(error.response.data.Content, "error")
  },
}

const httpGO = createAuthClient(API_URL_GO, authService, { onStatus: onStatusHandler })
const http = createAuthClient(API_URL, authService, { onStatus: onStatusHandler })

const getGO = (url: string, headers = {}, params = {}) => {
  return httpGO.get(url, {
    ...params,
    headers: {
      ...headers,
    }
  });
};

const postGO = (url: string, data: any, headers = {}, params = {}) => {
  return httpGO.post(url, data, {
    ...params,
    headers: {
      ...headers
    }
  });
};

const removeGO = (url: string, data: any, headers = {}) => {
  return httpGO.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};

const patchGO = (url: string, data: any, headers = {}) => {
  return httpGO.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const putGO = (url: string, data: any, headers = {}) => {
  return httpGO.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const get = (url: string, headers = {}, params = {}) => {
  return http.get(url, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const post = (url: string, data: any, headers = {}, params = {}) => {
  return http.post(url, data, {
    ...params,
    headers: {
      ...headers,
    },
  });
};

const put = (url: string, data: any, headers = {}) => {
  return http.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = (url: string, data: any, headers = {}) => {
  return http.delete(url, {
    headers: {
      ...headers,
    },
    data,
  });
};


const patch = (url: string, data: any, headers = {}) => {
  return http.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const module = {
  isAPIGO,
  getGO,
  postGO,
  removeGO,
  patchGO,
  putGO,
  http,
  httpGO,
  get,
  post,
  put,
  remove,
  patch,
};

export default module;
