import { createUrl } from "../../helpers/url";
import http from "../config/https";

export const getFeatureFlagStatus = (flagKey: string): Promise<any> => {
    const featureFlagHost = process.env.REACT_APP_FEATURE_FLAG_HOST || "https://appconfig.dev.engagerocket.co";
    const featureFlagEnvironment = process.env.REACT_APP_FEATURE_FLAG_ENV || "development";
    const featureFlagProfile = process.env.REACT_APP_FEATURE_FLAG_PROFILE || "engagerocket_configurations_profile";

    if (!featureFlagHost || !featureFlagEnvironment || !featureFlagProfile) return null;

    const query: {
        flag: string;
    } = {
        flag: flagKey,
    };

    const url = createUrl({
        baseUrl: featureFlagHost,
        path: `/applications/engagerocket/environments/${featureFlagEnvironment}/configurations/${featureFlagProfile}`,
        query: query,
    });
    return http.getGO(url);
};