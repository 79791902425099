import { makeAutoObservable, reaction } from "mobx";
import { getOneEntity } from "api/sharp/useGetOneEntity";
import { addOrEditEntity } from "api/sharp/useAddOrEditEntity";
import { TOrganizationSize, TCountry, TIndustry, TSubIndustry, TEntity } from "types/entity";
import MainStore from "MainStore";
import { getSubIndustries } from "api/sharp/useGetSubIndustries";
import { getIndustries } from "api/sharp/useGetIndustries";
import { getCountries } from "api/sharp/useGetCountries";
import { getOrganizationSizes } from "api/sharp/useGetOrganizationSizes";
import { checkEntityNameUnique } from "api/sharp/useCheckEntityNameUnique";
import { LOGIN_OPTIONS } from "constants/signInOptions";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";
import { UNAVAILABLE_LOGIN_METHODS_FEATURE_FLAG } from "constants/feature_flag";

class Store {
  entityId: number = 0;
  workspaceId: number = 0;
  name: string = "";
  oldName: string = "";
  signInMethod: string = LOGIN_OPTIONS[0].value;
  industryId: number = 0;
  subIndustryId: number = 0;
  countryId: number = 0;
  sizeOrganizationId: number = 0;
  file = null
  logoUrl = ""

  errorfile = ""
  errorname: string = "";
  errorDelayCheckName: boolean = false;
  unavailableLoginMethods: string[] = [];

  industry: TIndustry = null;
  country: TCountry = null;
  subIndustry: TSubIndustry = null;
  organizationSize: TOrganizationSize = null;
  industries: TIndustry[] = [];
  countries: TCountry[] = [];
  subIndustries: TSubIndustry[] = [];
  selectedSubIndustries: TSubIndustry[] = [];
  organizationSizes: TOrganizationSize[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.name,
      async (newName) => {
        if (newName === "") return;
        await this.delayedCheckNameUniqueness(newName);
      },
      { delay: 1000 }
    );
  }

  clearStore() {
    this.entityId = 0;
    this.workspaceId = 0;
    this.name = "";
    this.oldName = "";
    this.signInMethod = LOGIN_OPTIONS[0].value;
    this.industryId = 0;
    this.subIndustryId = 0;
    this.countryId = 0;
    this.sizeOrganizationId = 0;
    this.file = null;
    this.errorfile = "";
    this.logoUrl = ""
    this.errorname = "";
    this.country = null;
    this.industry = null;
    this.subIndustry = null;
    this.organizationSize = null;
    this.errorDelayCheckName = false;
  }

  async doLoad(id: number, workspaceId: number, workspaceLogo: string) {
    this.setData("entityId", id);
    this.setData("workspaceId", workspaceId);
    if (id !== 0) {
      await this.getEntity(id);
    }
    this.getCountries();
    this.getIndustries();
    this.getOrganizationSizes();
    this.getUnavailabeLoginMethods();
  }

  setData = (field: string, value: any) => {
    this[field] = value;
    if (field === "industry") {
      this.setData("subIndustry", null)
      const subIndustries = this.subIndustries.filter(x => x.industry_id === value?.id);
      this.setData("selectedSubIndustries", subIndustries)
    }
  };

  private async delayedCheckNameUniqueness(newName: string) {
    try {
      const response = await checkEntityNameUnique(newName, this.workspaceId, this.entityId);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        if (response.data.exists && this.oldName !== this.name) {
          this.setData("errorname", "There is an existing entity with the same name.");
        } else {
          this.setData("errorname", "");
        }
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      this.setData("errorDelayCheckName", false)
    }
  }

  getEntity = async (id: number) => {
    try {
      MainStore.changeLoader(true);
      const response = await getOneEntity(id);
      if (response.status === 200 || response.status === 201) {
        this.setEntity(response.data);
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getUnavailabeLoginMethods = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getFeatureFlagStatus(UNAVAILABLE_LOGIN_METHODS_FEATURE_FLAG);
      if (response.status === 200 || response.status === 201) {
        this.unavailableLoginMethods = response.data[process.env.REACT_APP_FEATURE_FLAG_ENV ?? "development"] ?? [];
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getIndustries = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getIndustries();
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setData("industries", response.data);
        this.setData("industry", response.data.find(x => x.id === this.industryId));
        this.getSubIndustries();
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getCountries = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getCountries();
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setData("countries", response.data);
        this.setData("country", response.data.find(x => x.id === this.countryId));
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
  getSubIndustries = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getSubIndustries();
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setData("subIndustries", response.data);
        this.setData("subIndustry", response.data.find(x => x.id === this.subIndustryId));
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getOrganizationSizes = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getOrganizationSizes();
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.setData("organizationSizes", response.data);
        this.setData("organizationSize", response.data.find(x => x.id === this.sizeOrganizationId));
      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  setEntity(data: TEntity) {
    this.oldName = data.name;
    this.name = data.name;
    this.entityId = data.id
    this.logoUrl = data.logo_url;
    this.signInMethod = data.sign_in_methods[0];
    this.countryId = data.country_id;
    this.industryId = data.industry_id;
    this.subIndustryId = data.sub_industry_id;
    this.sizeOrganizationId = data.organization_size_id;
  }

  changeFile(value: any) {
    if (value == null) {
      if (this.errorfile !== "") {
        this.errorfile = "";
      }
      this.logoUrl = null
      this.file = null;
      return;
    }
    if (value.size > 1000000) {
      this.errorfile = "Image not uploaded as it is larger than 1MB.";
    } else {
      this.file = value;
      if (this.errorfile !== "") {
        this.errorfile = "";
      }
    }
  }

  changeName(value: string) {
    if (value === "") {
      this.errorname = "Field Required"
    } else {
      this.setData("errorDelayCheckName", true)
      if (this.errorname !== "") {
        this.errorname = ""
      }
    }
    this.name = value
  }

  async saveEntity(props: any) {

    var entity = {
      id: this.entityId,
      name: this.name,
      workspace_id: props.workspaceId,
      file: this.file,
      logo_url: this.logoUrl,
      country_id: this.country?.id,
      industry_id: this.industry?.id,
      sub_industry_id: this.subIndustry?.id,
      sign_in_method: this.signInMethod,
      organization_size_id: this.organizationSize?.id,
    };

    try {
      MainStore.changeLoader(true);
      const response = await addOrEditEntity(entity);
      if ((response.status === 200 || response.status === 201) && response?.data !== null) {
        this.clearStore();
        props.onSaved();

      } else throw new Error();
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  }

}

const store = new Store();
export default store;
