import { useMutation } from "@tanstack/react-query";
import { queryClient } from "providers/ReactQueryProvider";

import http from "./config/https";

const createEntity = async (entityData: {
  name: string;
  sign_in_method: string;
  workspace_id: number;
  super_admin: boolean;
}) => {
  const { data } = await http.post(`/entity/addOrUpdate2`, entityData, {});
  return data;
};

export const useCreateEntity = () => {
  return useMutation({
    mutationFn: createEntity,
    onSuccess: () => {
      queryClient.resetQueries(["workspace/entity_list"]);
    },
  });
};
