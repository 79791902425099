import { FC } from "react";
import { TWorkspace } from "types/workspace";
import { TEntity } from "types/entity";

import Link from "@mui/material/Link";
import styled from "styled-components";
import Button from "components/button/Button";
import CustomTable from "components/table/CustomTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { SIGN_IN_METHOD_MAPPINGS } from "constants/signInOptions";

type WorkspacesTableProps = {
  list: TWorkspace[];
  sort_by: string | undefined;
  sort_type: string | undefined;
  handleParams: (newParams: { [key: string]: string | undefined }) => void;
  search_by: string | undefined;
  search_value: string;
  sortModel: { field: string | null; sort: string | null };
  changeSort: (field: string, sort: string) => void;
};

export const WorkspacesTable: FC<WorkspacesTableProps> = (props) => {

  const renderEntities = (workspace: TWorkspace) => {
    return workspace.entities.map((entity, index) => (
      <TableRow key={`${entity.id}_${index}`}>
        <TableCell>{entity.name}</TableCell>
        <TableCell>{entity.id}</TableCell>
        <TableCell>{SIGN_IN_METHOD_MAPPINGS[entity?.sign_in_methods[0]]}</TableCell>
        <TableCell>{new Date(entity.created_at).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })}</TableCell>
        {/* Empty cells for other columns */}
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    ));
  };

  const tableData = {
    headers: [
      {
        title: "Workspace / Entity name",
        value: "name",
        filter: true,
        sort: true,
        renderRow: (row: TWorkspace, index, indexRow) => (
          <TableName id={`Workspace_Table_WorkspaceEntityName_${index}_${indexRow}_${row.id}`}>
            {row.name} ({row.entities.length})
          </TableName>
        ),
        renderHeader: () => {
          return <p id="Workspace_ColumnName_WorkspaceEntityName">Workspace / Entity name</p>
        }
      },
      {
        title: "Workspace / Entity ID",
        value: "id",
        filter: true,
        sort: true,
        renderRow: (row: TWorkspace, index, indexRow) => <TableText id={`$Workspace_Table_WorkspaceEntityID_${index}_${indexRow}_${row.id}`}>{row.id}</TableText>,
        renderHeader: () => {
          return <p id="Workspace_ColumnName_WorkspaceEntityID">Workspace / Entity ID</p>
        }
      },
      {
        title: "Sign in method",
        value: "sign_in_method",
        filter: true,
        sort: false,
        renderRow: (row: TWorkspace, index, indexRow) => (
            <TableText id={`$Workspace_Table_SignInMethod_${index}_${indexRow}_${row.id}`}>
            {row.sign_in_methods && row.sign_in_methods.length > 0 
              ? SIGN_IN_METHOD_MAPPINGS[row.sign_in_methods[0]] 
              : '-'}
            </TableText>
        ),
        renderHeader: () => {
          return <p id="Workspace_ColumnName_SignInMethod">Sign in method</p>
        }
      },
      {
        title: "Date created",
        value: "created_at",
        filter: true,
        sort: false,
        renderRow: (row: TWorkspace, index, indexRow) => (
          <TableText
          id={`$Workspace_Table_DateCreated_${index}_${indexRow}_${row.id}`}
          >
            {new Date(row.created_at).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
          </TableText>
        ),
        renderHeader: () => {
          return <p id="Workspace_ColumnName_DateCreated">Date created</p>
        },
      },
      {
        title: "",
        value: "button_link",
        filter: false,
        sort: false,
        renderRow: (row: TWorkspace, index, indexRow) => (
          <ButtonsWrapper>
            <StyledLink id={`Workspace_table_${index}_${indexRow}_SubscriptionSettingsLink_${row.id}`} href={`/workspace-settings/${row.id}`}>
              <ManageButton id={`Workspace_table_${index}_${indexRow}_SubscriptionSettingsBtn_${row.id}`} variant="outlined">
                Subscription settings
              </ManageButton>
            </StyledLink>
            <StyledLink id={`Workspace_table_${index + 1}_${indexRow}_ManageWorkspaceLink_${row.id}`} href={`/workspaces/${row.id}`}>
              <ManageButton id={`Workspace_table_${index + 1}_${indexRow}_ManageWorkspaceBtn_${row.id}`} variant="outlined">
                Manage workspace & entities
              </ManageButton>
            </StyledLink>
          </ButtonsWrapper>
        ),
      },
    ],
  };

  return (
    <CustomTable
    id="Workspace_Grid"
    list={props.list}
    data={tableData}
    sort_by={props.sort_by}
    sort_type={props.sort_type}
    search={props.handleParams}
    sort={props.changeSort}
    sortModel={props.sortModel}
    search_by={props.search_by}
    search_value={props.search_value}
    renderCustomRows={(row: TWorkspace) => renderEntities(row)}
  />
  );
};

const TableText = styled.p`
  margin: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--colorNeutralForeground1);
`;

const TableName = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--colorPaletteVioletBackground1);
  margin: 0;
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-decoration: none !important;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ManageButton = styled(Button)`
  height: 36px;
  align-items: center;
`;
