import http from "./config/https";

export const saveReopenSurvey = (
  surveyId: number,
  endDate: string,
) =>
  http.postGO(`/sm/sm_survey/ReopenSurvey`, {
    survey_id: surveyId,
    end_date: endDate,
  });
