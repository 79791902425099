import { makeAutoObservable, runInAction } from "mobx";
import { getActionSuggestions } from "api/go/useGetActionSuggestions";
import { ActionSuggestion } from "types/act";
import { useGeneratePresignURL } from "api/go/useGeneratePresignURL";
import axios from "axios";
import MainStore from "MainStore";
import SurveyStore from "../store";
import { v4 as uuidv4 } from "uuid";
import { useUploadGetActionSuggestionStatus } from "api/go/useUploadGetActionSuggestionStatus";

class Store {
  surveyId: number = 0;

  actionSuggestions: ActionSuggestion[] = [];
  filterValue?: string;
  filterField?: 'dimension' | 'title' | 'description' | 'dimensionStrength' | 'authorName';
  filterOperator?: string;

  sortField?: 'dimension' | 'title' | 'description' | 'dimensionStrength' | 'authorName';
  sortType?: 'asc' | 'desc';
  searchValue?: string;
  status?: 'EXCEL_IMPORT_SUCCESS' | 'EXCEL_IMPORT_ONGOING' | 'EXCEL_IMPORT_FAILED' = 'EXCEL_IMPORT_ONGOING';
  progress?: number;

  page: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  unfilteredTotalItems: number = 0;
  
  // Add a property to track the timeout ID
  private statusPollingTimeoutId: number | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  clearStore = () => {
    // Clear the timeout to prevent memory leaks when navigating away
    if (this.statusPollingTimeoutId !== null) {
      clearTimeout(this.statusPollingTimeoutId);
      this.statusPollingTimeoutId = null;
    }
    
    // Reset other state if needed
    this.actionSuggestions = [];
    this.status = 'EXCEL_IMPORT_ONGOING';
    this.progress = null;
  };

  setData = (field: string, value: any) => {
    this[field] = value;
  };

  doLoad = (surveyId: number) => {
    this.surveyId = surveyId;
    this.getActionSuggestionStatus();
  };

  getActionSuggestions = async () => {
    MainStore.changeLoader(true);
    try {
      const body = {
        surveyId: this.surveyId,
        page: this.page,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortType: this.sortType,
        filterValue: this.filterValue,
        filterField: this.filterField,
        filterOperator: this.filterOperator,
        searchValue: this.searchValue,
      };
      const response = await getActionSuggestions(body);
      runInAction(() => {
        this.status = 'EXCEL_IMPORT_SUCCESS';
        this.actionSuggestions = response.data.data.items;
        this.totalPages = response.data.data.pagination.totalPages;
        this.page = response.data.data.pagination.currentPage;
        this.pageSize = response.data.data.pagination.pageSize;
        this.totalItems = response.data.data.pagination.totalItems;
        this.unfilteredTotalItems = response.data.data.pagination.unfilteredTotalItems;
      });
    } catch (error) {
      console.error(error);
    } finally {
      MainStore.changeLoader(false);
    }
  };

  parseExcelFile = async (file: File) => {
    try {
      MainStore.changeLoader(true);
      const id = uuidv4();
      const response = await useGeneratePresignURL("action-suggestion-import", {
        "survey_id": this.surveyId,
        "entity_id": SurveyStore.survey.entity_id,
        "filename": file.name,
        "import_id": id,
      });
    const presignedUrl = response.data.url;

    await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
      },
    });

    MainStore.changeLoader(true);
    this.statusPollingTimeoutId = setTimeout(() => {
      this.getActionSuggestionStatus();
    }, 5000) as unknown as number;

    } catch (error) {
      MainStore.setSnackbar(error.response.data.message, 'error');
    } finally {
      MainStore.changeLoader(false);
    }
  };

  getActionSuggestionStatus = async () => {
    try {
      this.status = 'EXCEL_IMPORT_ONGOING';
      this.progress = null;
      this.actionSuggestions = [];
      const response = await useUploadGetActionSuggestionStatus(this.surveyId);
      runInAction(() => {
      this.progress = response.data.progress;
      if (response.data.status === 'EXCEL_IMPORT_SUCCESS' && response.data.progress == 100 && response.data.errors === null) {
        this.status = 'EXCEL_IMPORT_SUCCESS';
        this.getActionSuggestions();
      } else if (response.data.status === 'EXCEL_IMPORT_ONGOING') {
        this.status = 'EXCEL_IMPORT_ONGOING';
        // Clear any existing timeout before setting a new one
        if (this.statusPollingTimeoutId !== null) {
          clearTimeout(this.statusPollingTimeoutId);
        }
        
        // Store the timeout ID so we can clear it later
        this.statusPollingTimeoutId = setTimeout(() => {
          this.getActionSuggestionStatus();
        }, 1000) as unknown as number;
      } else if (response.data.status === 'EXCEL_IMPORT_FAILED') {
        this.status = 'EXCEL_IMPORT_FAILED';
        MainStore.setSnackbar(response.data.message, 'error');
      }
      });
    } catch (error) {
      runInAction(() => {
        if (error.response) {
          if (error.response.data && error.response.data.error === 'Not Found' && error.response.data.statusCode === 404) {
            this.status = 'EXCEL_IMPORT_SUCCESS';
            this.getActionSuggestions();
          } else {
            this.status = 'EXCEL_IMPORT_FAILED';
            MainStore.setSnackbar(error.response.data?.message || 'An error occurred', 'error');
          }
        } else {
          this.status = 'EXCEL_IMPORT_FAILED';
          MainStore.setSnackbar('Network error occurred. Please try again.', 'error');
        }
      });
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
