import { TWorkspace } from "types/workspace";
import { useCreateEntity } from "api/useCreateEntity";
import { useCheckCorrectEntityName } from "api/useCheckCorrectEntityName";
import { Input, Radio } from "@mui/material";
import React, { FC, ReactElement, useEffect, useState } from "react";

import styled from "styled-components";
import Button from "components/button/Button";
import RadioGroup from "@mui/material/RadioGroup";
import CustomModal from "components/modal/Modal";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import useDebounce from "utils/useDebounse";
import { LOGIN_OPTIONS } from "constants/signInOptions";

type ModalCreateEntityProps = {
  open: boolean;
  hide: (state: boolean) => void;
  workspaceInfo: TWorkspace | undefined;
};

const ModalCreateEntity: FC<ModalCreateEntityProps> = (props): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [signInMethod, setSignInMethod] =useState<string>("");

  const { mutateAsync: createEntity, isLoading: isLoadingCreateEntity } = useCreateEntity();
  const { mutateAsync } = useCheckCorrectEntityName();
  const debouncedNameValue = useDebounce(name, 500);

  const checkName = async () => {
    try {
      if (!name) {
        setError("Name is required");
        return;
      }
      if (props.open && debouncedNameValue && debouncedNameValue.length > 0) {
        const response = await mutateAsync({
          name: debouncedNameValue,
          workspaceId: props.workspaceInfo.id,
        });
        if (!response.exists) setError(null);
        else setError("There is an existing entity with the same name.");
      } else return;
    } catch (err) {
      setError("There is an existing entity with the same name.");
    }
  };

  useEffect(() => {
    checkName();
  }, [debouncedNameValue]);

  const submitForm = () => {
    try {
      createEntity({
        name,
        workspace_id: props.workspaceInfo.id,
        sign_in_method: signInMethod,
        super_admin: true, //TODO only for demo
      });
      closeModal();
    } catch (err) {}
  };

  const closeModal = () => {
    setName("");
    setSignInMethod("");
    props.hide(false);
  };

  return (
    <CustomModal open={props.open} hide={closeModal} width="468">
      <StyledModalTitle id="Entity_CreateEntityPopUp_Title">
        Create entity under {props.workspaceInfo?.name}
      </StyledModalTitle>
      <StyledModalContent aria-label="Create entity form">
        <InputLabel id="Entity_CreateEntityPopUp_Subtitle">
          Entity name
        </InputLabel>
        <StyledInput
          id="Entity_CreateEntityPopUp_Input"
          name="name"
          placeholder="Enter entity name"
          type="text"
          value={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
        />
        {error && <Error id="Entity_CreateEntityPopUp_Error">{error}</Error>}
        
        <InputLabel id="Entity_CreateEntityPopUp_Question">
          How will entity users sign in?
        </InputLabel>
        
        <FormControl fullWidth>
          <RadioOptions
            aria-labelledby="signin-options-group"
            name="signin-options"
            value={signInMethod}
            onChange={(e) => setSignInMethod((e.target.value))}
          >
            {LOGIN_OPTIONS.map((option) => (
              <OptionWrapper key={option.id}>
                <StyledRadio
                  id={option.testId}
                  value={option.value}
                  control={<Radio />}
                  label={
                    option.content ? (
                      <OptionContent>
                        <OptionLabel>{option.name}</OptionLabel>
                        {option.content}
                      </OptionContent>
                    ) : (
                      option.name
                    )
                  }
                />
              </OptionWrapper>
            ))}
          </RadioOptions>
        </FormControl>
        
        <ButtonContainer>
          <CreateButton
            id="Entity_CreateEntityPopUp_CreateEntity_Btn"
            variant="contained"
            onClick={submitForm}
            disabled={Boolean(error) || isLoadingCreateEntity}
          >
            Create entity
          </CreateButton>
          <CancelButton
            id="Entity_CreateEntityPopUp_Cancel_Btn"
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </CancelButton>
        </ButtonContainer>
      </StyledModalContent>
    </CustomModal>
  );
};

export default ModalCreateEntity;

const StyledModalTitle = styled.span`
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 20px;
  display: block;
`;

const StyledModalContent = styled.div`
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;

  input {
    font-size: 18px;
    color: var(--colorNeutralForeground1);

    &::placeholder {
      font-size: 18px;
      font-weight: 400;
      color: var(--colorNeutralForeground5);
    }
  }
`;

const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 24px 0 10px 0 !important;
  color: var(--colorNeutralForeground2);
`;

const Error = styled.p`
  width: 100%;
  margin: 4px 0 0 0;
  color: var(--colorPaletteRedForeground2);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;

const RadioOptions = styled(RadioGroup)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
`;

const OptionWrapper = styled.div`
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: var(--colorPaletteBlueBackground1Hover, rgba(0, 120, 212, 0.05));
  }
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  padding: 16px;
  margin: 0 !important;
  
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    width: 100%;
  }
`;

const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const OptionLabel = styled.p`
  margin: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  gap: 16px;
`;

const CreateButton = styled(Button)`
  height: 36px;
  min-width: 150px;
`;

const CancelButton = styled(Button)`
  height: 36px;
  min-width: 75px;
  color: var(--colorNeutralForeground1) !important;
  border: 1px solid var(--colorNeutralForeground1) !important;
`;