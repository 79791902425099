import React, { FC, ReactElement, useEffect } from "react";
import store from "./store";
import {
  DialogContent,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import styled from "styled-components";
import { observer } from "mobx-react";
import FileInput from "components/uploadImage";
import { Dialog } from "@mui/material";
import CustomButton from "components/button/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete } from "@mui/material";
import { LOGIN_OPTIONS, SIGN_IN_METHOD_MAPPINGS } from "constants/signInOptions";


type ModalChangePasswordProps = {
  openPanel: boolean;
  onBtnCancelClick: () => void;
  onSaved: () => void;
  id: number;
  workspaceId: number;
  workspacename: string;
  workspaceLogo: string;
};

const ModalCreateWorkspace: FC<ModalChangePasswordProps> = observer((props): ReactElement => {
  useEffect(() => {
    if (props.openPanel) {
      store.doLoad(props.id, props.workspaceId, props.workspaceLogo);
    }
  }, [props.openPanel]);

  return (
    <Dialog
      open={props.openPanel}
      onClose={() => {
        props.onBtnCancelClick();
        store.clearStore();
      }}
    >
      <StyledModalContent>
        <Wrapper>
          <HeaderText id="EntityAddEdit_Title">
            {store.entityId !== 0 ? `Edit entity` : `Create entity under ${props.workspacename}`}
          </HeaderText>
          <TextField
            name="name"
            id="EntityAddEdit_EntityName_Input"
            placeholder="Enter entity name"
            fullWidth
            label="Entity name"
            variant="standard"
            helperText={store.errorname}
            error={store.errorname !== ""}
            value={store.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => store.changeName(e.target.value)}
          />

          <FormControl>
            <SelectInputHeader style={{ marginTop: "21px" }} id="EntityAddEdit_EntityName_SignInMethod">
              How will entity users sign in?
            </SelectInputHeader>
            {
              store.entityId !== 0 ?
                <Text>
                  {SIGN_IN_METHOD_MAPPINGS[store.signInMethod]}
                </Text>
                :
                <RadioWrapper
                  id="EntityAddEdit_EntityName_Radio"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={store.signInMethod}
                  onChange={(e) => store.setData("signInMethod", e.target.value)}
                >
                  {LOGIN_OPTIONS
                    .filter(option => !store.unavailableLoginMethods.includes(option.id))
                    .map((option) => (
                    <StyledRadioWrapper key={option.id}>
                      <StyledRadio
                        id={option.testId}
                        value={option.value}
                        control={<Radio />}
                        disabled={props.id !== 0}
                        label={
                          <React.Fragment>
                            <RadioLabel id={`${option.testId}_Label`}>
                              {option.name}
                            </RadioLabel>
                            {option.content}
                          </React.Fragment>
                        }
                      />
                    </StyledRadioWrapper>
                  ))}
                </RadioWrapper>
            }
          </FormControl>

          <FileInput
            Id="EntityAddEdit_EntityName_UploadImage"
            onFileSelect={(file) => store.changeFile(file)}
            label={"Company logo (Optional)"}
            secondaryLabel={"Only .jpg and .png formats with max file size of 1MB accepted"}
            errorText={store.errorfile}
            logoURL={store.logoUrl}
          />

          <EntityDetailsText id="EntityAddEdit_EntityName_Details">
            Entity details
          </EntityDetailsText>

          <SelectInputHeader id="EntityAddEdit_EntityName_Industry">
            Industry of this entity <RequiredSign>*</RequiredSign>
          </SelectInputHeader>

          <GridIndustry>
            <StyledAutocomplete
              id="EntityAddEdit_SelectIndustry"
              options={store.industries}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  placeholder="Select industry"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              getOptionLabel={(option: any) => option.name}
              value={store.industry}
              onChange={(_, value: any) => store.setData("industry", value)}
              popupIcon={<KeyboardArrowDownIcon />}
              isOptionEqualToValue={(option, value) => option === value}
            />

            <StyledAutocomplete
              id="EntityAddEdit_SelectSubIndustry"
              options={store.selectedSubIndustries}
              disabled={!store.industry}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  placeholder="Select industry"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              getOptionLabel={(option: any) => option.name}
              value={store.subIndustry}
              onChange={(_, value: any) => store.setData("subIndustry", value)}
              popupIcon={<KeyboardArrowDownIcon />}
              isOptionEqualToValue={(option, value) => option === value}
            />
          </GridIndustry>

          <SelectInputHeader id="EntityAddEdit_EntityName_Country_label">
            Country of this entity <RequiredSign>*</RequiredSign>
          </SelectInputHeader>
          <StyledAutocomplete
            id="select_template_filter_Country"
            options={store.countries}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Select sub-industry"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            getOptionLabel={(option: any) => option.name}
            value={store.country}
            onChange={(_, value: any) => store.setData("country", value)}
            popupIcon={<KeyboardArrowDownIcon />}
            isOptionEqualToValue={(option, value) => option === value}
          />

          <SelectInputHeader id="EntityAddEdit_EntityName_OrganizationSize_Label">
            Organization size of this entity <RequiredSign>*</RequiredSign>
          </SelectInputHeader>

          <StyledAutocomplete
            id="EntityAddEdit_EntityName_OrganizationSize_Filter"
            options={store.organizationSizes}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder="Select industry"
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            getOptionLabel={(option: any) => option.name}
            value={store.organizationSize}
            onChange={(_, value: any) => store.setData("organizationSize", value)}
            popupIcon={<KeyboardArrowDownIcon />}
            isOptionEqualToValue={(option, value) => option === value}
          />

          <ButtonWrapper>
            <StyledNextButton
              id="EntityAddEdit_EntityName_SaveChanges_Btn"
              variant="contained"
              disabled={
                store.errorname !== "" ||
                store.errorDelayCheckName ||
                store.errorfile !== "" ||
                !store.industry ||
                !store.country ||
                !store.organizationSize ||
                !store.signInMethod
              }
              onClick={() => store.saveEntity(props)}
            >
              Save changes
            </StyledNextButton>
            <StyledCancelButton
              id="EntityAddEdit_EntityName_Cansel_Btn"
              variant="outlined"
              onClick={() => {
                props.onBtnCancelClick();
                store.clearStore();
              }}
            >
              Cancel
            </StyledCancelButton>
          </ButtonWrapper>
        </Wrapper>
      </StyledModalContent>
    </Dialog>
  );
});

const StyledModalContent = styled(DialogContent)``;

const Wrapper = styled.div`
  margin: 30px 10px;
  width: 500px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
`;

const StyledCancelButton = styled(CustomButton)``;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  margin-right: 20px !important;
  max-width: 150px !important;
`;

const GridIndustry = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;

const SelectInputHeader = styled.span`
  color: var(--colorNeutralForeground2);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const EntityDetailsText = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  margin: 15px 0 5px 0;
`;

const RequiredSign = styled.span`
  color: var(--colorPaletteRedForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  margin: 7px 0;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  width: 250px;

  input {
    width: 180px !important;
    border-radius: 2px !important;
    padding: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .MuiOutlinedInput-root {
    border-radius: 2px !important;
  }
`;

const StyledTextField = styled(TextField)`
  ::placeholder {
    color: var(--colorNeutralForeground5);
    font-weight: 400 !important;
    font-family: Roboto, sans-serif !important;
  }
`;

const RadioWrapper = styled(RadioGroup)`
  margin-top: 15px;
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  column-gap: 11px;
`;

const StyledRadio = styled(FormControlLabel)`
  width: 100%;
  max-width: 164px;
  padding: 30px 11px;
  margin: 0 !important;

  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
`;

const StyledRadioWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  position: relative;
`;

const RadioLabel = styled.p`
  margin: 0;
`;

const HeaderText = styled.div`
  color: var(--colorNeutralForeground1);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 25px;
`;

const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 10px 0 0 0;
  color: var(--colorNeutralForeground1);
`;


export default ModalCreateWorkspace;