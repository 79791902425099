import React from 'react';
import { Box, Typography, LinearProgress, Paper, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import UploadFileIcon from '@mui/icons-material/UploadFile';


interface ActSuggestionProgressPageProps {
  filename?: string;
  fileSize?: string;
  progress?: number;
  isInfinite?: boolean;
}

const ActSuggestionProgressPage: React.FC<ActSuggestionProgressPageProps> = ({
  filename = 'Uploading File',
  fileSize = '',
  progress = 0,
  isInfinite = false,
}) => {
  return (
    <Box sx={{  maxWidth: '100%' , display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>

    <Box sx={{ width: '60%', display: 'flex', justifyContent :'start' , flexDirection :'column' }}>

      <StyledPaper>
          <Box>
            <FileIcon />
            <Box sx={{ width: '100%' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                <Typography variant="subtitle1" fontWeight="medium" width={'100%'} >
                  {filename}
                </Typography>

                <ProgressContainer>
                  {isInfinite ? (
                    <StyledLinearProgress variant="indeterminate" />
                  ) : (
                    <>
                      <StyledLinearProgress 
                        variant="determinate" 
                        value={progress} 
                      />
                      <ProgressPercentage variant="body2">
                        {progress}%
                      </ProgressPercentage>
                    </>
                  )}
                </ProgressContainer>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {fileSize}
              </Typography>
            </Box>
          </Box>
      </StyledPaper>

      <Typography variant="body2" color="text.secondary" marginTop={2} marginBottom={2}>
        You can continue using the platform while the file is being processed in the background.
      </Typography>


    </Box>

      
    </Box>
  );
};

export default ActSuggestionProgressPage;



const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
    maxWidth: '1000px',
    width: '100%',
    margin: '0 auto',
  }));
  
  const FileIcon = styled(UploadFileIcon)({
    color: 'var(--colorPaletteVioletBackground3)',
    fontSize: '2rem',
  });
  
  const ProgressContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    width: '180px', // Set a fixed width to ensure visibility
  });
  
  const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: 4,
    width: '100%', // Ensure it takes full width of container
    backgroundColor: 'var(--colorNeutralForeground10)',
    '& .MuiLinearProgress-bar': {
      borderRadius: 4,
      backgroundColor: 'var(--colorPaletteVioletBackground3)', // Ensure the bar has a visible color
    },
  }));
  
  const ProgressPercentage = styled(Typography)(({ theme }) => ({
    minWidth: '40px',
    textAlign: 'right',
    color: theme.palette.text.secondary,
  }));
